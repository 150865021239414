const  SelectComponent = Formio.Components.components.select;

class BetterSelectComponent extends SelectComponent {
  static schema(...extend) {
      return SelectComponent.schema({
        label: 'Better Select',
        type: 'betterSelect',
      }, ...extend);
    }
    
    static get builderInfo() {
      return {
        title: 'Better Select',
        group: 'basic',
        icon: 'th-list',
        weight: 70,
        documentation: '/userguide/forms/form-components#select',
        schema: BetterSelectComponent.schema()
      };
    }
    getRandomComponentId() {
      return `e${Math.random().toString(36).substring(7)}`;
    }
    addOption(value, label, attrs = {}, id = this.getRandomComponentId()){
      if (value === null || value === ""){
        id = ""
      }
      super.addOption(value, label, attrs , id)
    }
    setValue(value, flags={}) {
      if (this.component.dataSrc === 'url'){
        let row = this._data;
        const limit = this.component.limit || 200;
        const skip = this.isScrollLoading ? this.selectOptions.length : 0;
        row.limit = limit;
        row.skip = skip;

        const url = this.interpolate(this.component.data.url, row);

        if (this.component.ds == null){
          this.component.ds = {}
          this.component.ds[this.component.selectValues] = []
        }
        if (this.component.ds != null){
          if(this.component.ds[this.component.selectValues].length === 0 || this.component.ds[this.component.selectValues].length === 1){
              fetch(url)
              .then(response => response.json())
              .then(data => {
                this.component.ds = data;
                let list_choises_values = this.component.ds[this.component.selectValues].map((item) => 
                  item[this.component.valueProperty]
                );

                if(!(list_choises_values.includes(value) || list_choises_values.includes(value.toString()))){
                  value = this.emptyValue;        
                }
                
                super.setValue(value,flags);
                super.redraw()
              });
          }else{
              let list_choises_values = this.component.ds[this.component.selectValues].map((item) => 
                item[this.component.valueProperty]
              );

              if(!(list_choises_values.includes(value) || list_choises_values.includes(value.toString())) ){
                value = this.emptyValue;        
              }
              super.setValue(value, flags);
          }
        }
      } else{
        super.setValue(value, flags);
      }
      
    }
}

Formio.use({
    components: {
        betterSelect: BetterSelectComponent
    }
});
